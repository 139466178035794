<template>
  <v-container>
    <v-form v-model="isFormValid" ref="form">
      <div class="mb-4" v-for="item in items" :key="item.key">
        <div v-if="!isDefaultCurrency(item.name)">
          <v-row class="mb-4">
            <h2 class="text">{{ item.name }}</h2>
          </v-row>
          <v-row>
            <v-col class="ma-0 pa-0" cols="12" sm="6" md="3">
              <v-text-field
                v-model="item.value"
                elevation="0"
                :label="item.name"
                :rules="validators"
                outlined
                solo
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row>
        <primary-button :onClick="emitInput" :loading="loading">{{
          $t('helpers.saveChanges')
        }}</primary-button>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import PrimaryButton from './buttons/PrimaryButton.vue';
import { formMixin } from '../mixins/formMixin';
import { formatCurrency } from '../mixins/formatCurrency';

export default {
  components: { PrimaryButton },
  name: 'EditAccountItem',
  mixins: [formMixin, formatCurrency],
  props: ['loading', 'validators', 'items'],
  data() {
    return {
      input: '',
      isFormValid: false,
    };
  },
  methods: {
    emitInput() {
      this.$refs.form.validate();

      if (this.isFormValid) {
        this.$emit('click', this.items);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/main.scss';
</style>
