<template>
  <title-wrapper :title="$t('components.systemSettings.title')">
    <collapse :title="$t('components.systemSettings.colapseTwoTitle')">
      <edit-account-item
        :title="$t('components.systemSettings.editAccountOneTitle')"
        :label="$t('components.systemSettings.editAccountOneTitle')"
        :value="settings.deliveryDays"
        @click="updateDeliveryDays"
        :loading="isUpdatingDays"
        :validators="validators.deliveryRules"
      ></edit-account-item
    ></collapse>
    <collapse :title="$t('components.systemSettings.colapseTwoTitle')">
      <edit-account-item
        :title="$t('components.systemSettings.editAccountTwoTitle')"
        :label="$t('components.systemSettings.editAccountTwoTitle')"
        :value="settings.salesDepartmentEmail"
        @click="updateSalesDepartmentEmail"
        :loading="isUpdatingSalesDepartmentEmail"
        :validators="validators.emailRules"
      ></edit-account-item
    ></collapse>
    <collapse :title="$t('components.systemSettings.colapseThreeTitle')">
      <edit-account-items-multiple
        :items="settings.currencies"
        @click="updateExchangeRate"
        :loading="isUpdatingExchangeRate"
        :validators="validators.currencyRules"
      ></edit-account-items-multiple
    ></collapse>
    <collapse :title="$t('components.systemSettings.colapseFourTitle')">
      <label>
        {{ $t('components.systemSettings.blockadeLabelTitle') }}
      </label>
      <v-checkbox
        v-model="settings.orderingBlockedWhenNoModulesInCart"
        :label="$t('components.systemSettings.blockadeCheckboxTitle')"
      ></v-checkbox>
      <primary-button :onClick="changeBlockade">{{
        $t('components.systemSettings.blockadeButtonTitle')
      }}</primary-button>
    </collapse>
  </title-wrapper>
</template>
<script>
import SettingsService from '../../../services/SettingsService';
import EditAccountItem from '../../../components/EditAccountItem.vue';
import Collapse from '../../../components/Collapse.vue';
import { userDataComputed } from '../../../store/helper';
import { validators } from '../../../validators/Validators';
import TitleWrapper from '../../../components/TitleWrapper.vue';
import VueI18n from '../../../plugins/i18n';
import EditAccountItemsMultiple from '../../../components/EditAccountItemsMultiple.vue';
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';

export default {
  name: 'SystemSettings',
  components: { Collapse, EditAccountItem, TitleWrapper, EditAccountItemsMultiple, PrimaryButton },
  data() {
    return {
      isUpdatingDays: false,
      isUpdatingSalesDepartmentEmail: false,
      isUpdatingExchangeRate: false,
      csvCompanyLoading: false,
      settings: {
        deliveryDays: 0,
        salesDepartmentEmail: '',
        currencies: [],
        orderingBlockedWhenNoModulesInCart: false,
      },
    };
  },
  methods: {
    async loadDefaultSettings() {
      try {
        const response = await SettingsService.get();
        this.settings = { ...response.data };
        this.settings = {
          ...this.settings,
          currencies: this.adjustCurrenciesSeparator(this.settings.currencies, '.', ','),
        };
      } catch (error) {
        console.error(error);
      }
    },
    async updateSettings(changedField) {
      try {
        this.settings.currencies = this.adjustCurrenciesSeparator(
          this.settings.currencies,
          ',',
          '.'
        );
        const response = await SettingsService.update(this.settings);
        this.settings = { ...response.data };
        this.$toasted.global.success({
          message: `${changedField} ${VueI18n.t('components.systemSettings.updateSettingsFunc')}`,
        });
        this.settings.currencies = this.adjustCurrenciesSeparator(
          this.settings.currencies,
          '.',
          ','
        );
      } catch (err) {
        console.error(err);
      }
    },
    async updateDeliveryDays(days) {
      this.isUpdatingDays = true;
      this.settings.deliveryDays = days;
      await this.updateSettings(this.$i18n.t('components.systemSettings.updateDeliveryDaysFunc'));
      this.isUpdatingDays = false;
    },
    async updateSalesDepartmentEmail(email) {
      this.isUpdatingSalesDepartmentEmail = true;
      this.settings.salesDepartmentEmail = email;
      await this.updateSettings(
        this.$i18n.t('components.systemSettings.updateSalesDepartmentEmailFunc')
      );
      this.isUpdatingSalesDepartmentEmail = false;
    },
    async updateExchangeRate(currencies) {
      this.isUpdatingExchangeRate = true;
      this.settings.currencies = currencies;
      await this.updateSettings(this.$i18n.t('components.systemSettings.updateExchangeRateFunc'));

      this.isUpdatingExchangeRate = false;
    },
    async changeBlockade() {
      await this.updateSettings(this.$i18n.t('components.systemSettings.changeBlockadeFunc'));
    },
    adjustCurrenciesSeparator(currencies, currentSeparator, desiredSeparator) {
      return currencies.map((currency) => {
        return {
          ...currency,
          value: currency.value.toString().replace(currentSeparator, desiredSeparator),
        };
      });
    },
  },
  computed: {
    ...userDataComputed,
    validators() {
      return validators;
    },
  },
  async created() {
    await this.loadDefaultSettings();
  },
};
</script>
<style lang="scss" scoped></style>
